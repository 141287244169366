<script>
  import Icons from "@/components/reuseables/Icons.vue";
  import {
    getAllUnits,
    getAllDept,
    transferPaddler,
  } from "@/services/api/APIService";
  import {
    mapGetters
  } from "vuex";

  export default {
    name: 'TransferPaddler',
    components: {
      Icons
    },
    data() {
      return {
        units: [],
        depts:[],
        departments: [],
        selectedDept: '',
        confirmDialog: false,
        loading: false,
        selectedStaffId: "",
        selectedDeptId: "",
        selectedDeptName: ""
      };
    },
    props: {
      toggleModal: {
        default: true,
        type: Boolean
      }
    },
    methods: {
      async getAllUnits() {
        try {
          const response = await getAllUnits();
          this.units = response.data;
        } catch (error) {
          console.error('Error fetching units:', error);
        }
      },
      async getAllDept(){
      try {
        const response = await getAllDept();
        this.depts = response.data;
      }catch (error){
        console.error('Error fetching departments:', error)
      }
    },
      toggleConfirmDialog() {
        this.confirmDialog = !this.confirmDialog
      },
      closeModal() {
        this.$emit('close-modal')
      },
      validateEntry() {
        if (this.selectedDeptId === this.paddlerData.departmentAssigned.id) {
          this.$displaySnackbar({
            message: "Staff already exist in selected department",
            success: false
          })
        } else {
          this.confirmDialog = true
        }
      },
      async transferPaddler() {
        this.loading = true
        let data = {}
        data.staffId = this.paddlerData.staffId
        data.departmentId = this.selectedDeptId
        await transferPaddler(data).then(() => {
          this.loading = false
          this.$displaySnackbar({
            message: "Paddler transferred successfully",
            success: true
          })
        }).catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          })
          this.confirmDialog = false
        })
        this.closeModal()
      }
    },
    computed: {
      ...mapGetters("paddler", ["paddlerData"])
    },
   async created() {
     await this.getAllUnits()
     await this.getAllDept()
    },

    watch: {
      "paddlerData.departmentAssigned.unitName": {
        handler: function (val) {
          if (val && this.units.length > 0) {
            const selectedUnit = this.units.find(unit => unit.name === val);
            this.departments = selectedUnit.departments;
          }
        },
        immediate: true,
        deep: true
      },
      'paddlerData.departmentAssigned.name': {
        handler(newValue) {
          if (newValue && this.depts.length > 0) {
            console.log(newValue)
            const selectedDept = this.depts.find(dept => dept.name === newValue);
            console.log(selectedDept, "selecetdDept")
            if (selectedDept) {
              this.selectedDeptName = selectedDept.name;
              this.selectedDeptId = selectedDept.id
            }
          }
        },
        immediate: true,
        deep:true,

      },
  }}
</script>

<template>
  <div>
    <v-dialog v-model="toggleModal" persistent transition="dialog-bottom-transition">
      <v-card>
        <p class="add-pd mb-6">Transfer Paddler</p>
        <div>
          <p class="darker-text mt-2 mb-5">
            You are about to transfer <span class="tw-font-bold">'{{paddlerData.firstname + ' ' + paddlerData.lastname }}'</span> to a different department. 
            Please select the department below.
          </p>
        <div class="mb-4">
            <label class="lighter-text">Unit</label>
          <v-text-field class="mt-4" hide-details disabled solo v-if="paddlerData && paddlerData.departmentAssigned && paddlerData.departmentAssigned.unitName" v-model="paddlerData.departmentAssigned.unitName">
          </v-text-field>
        </div>

        <div>
          <validation-provider name="department name" rules="required" v-slot="{ classes, errors }">
            <label class="lighter-text">Department</label>
            <v-select class="mt-4" :items="departments" hide-details item-text="name"
              placeholder="Select the target department for the transfer"  solo v-model="paddlerData.departmentAssigned.name">
              <template #append>
                <icons name="select-dropdown" />
              </template>
            </v-select>
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>
        </div>

  </div>
  <v-card-actions class="tw-flex tw-justify-end mt-5">
    <v-btn @click="closeModal" class="ctrl-btn back">Cancel</v-btn>
    <v-btn @click="validateEntry" class="ctrl-btn next">Transfer</v-btn>
  </v-card-actions>
  </v-card>
  <v-dialog v-model="confirmDialog" max-width="500">
    <v-card>
      <div class="add-pd">Confirm Transfer</div>
      <p class="lighter-text">
        Are you sure you want to transfer <span class="lighter-text" style="font-weight:bold">{{ paddlerData.firstname + " " + paddlerData.lastname }}</span> to
        <span class="lighter-text" style="font-weight:bold">{{ selectedDeptName }}</span>
      </p>
      <v-card-actions class="tw-flex tw-justify-end mt-5">
        <v-btn @click="toggleConfirmDialog" class="back">No</v-btn>
        <v-btn @click="transferPaddler" class="next" :loading="loading">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-dialog>
  </div>
</template>

<style scoped lang="scss">
  ::v-deep .v-dialog {
    box-shadow: none !important;
    width: 80% !important;

    @media screen and (min-width: 768px) {
      width: 700px !important;
    }
  }

  .v-card {
    padding: 40px;

    @media screen and (max-width: 576px) {
      padding: 20px;
    }
  }

  .add-pd {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 15px;

    @media (max-width:768px) {
      justify-content: start;
      margin-top: 13px;
      font-size: 17px;
    }
  }

  .ctrl-btn {
    width: 100px;
    height: 30px;
    border-radius: 10px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize !important;
    letter-spacing: 0;
  }

  .darker-text,
  .lighter-text {
    color: #263238;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  .lighter-text {
    color: #838383;
  }

  ::v-deep .v-btn.next {
    color: #ffff;
    background-color: #004aad !important;
  }

  ::v-deep .v-btn.back {
    color: #004aad;
    background-color: #ffff;
    border: 2px solid #004aad;
  }
</style>