<template>
  <div class="tw-flex tw-w-full tw-items-center tw-justify-center">
    <loader :loading="loading" v-if="loading" />
    <div class="tw-flex tw-justify-center" v-else>
      <div class="sculptor">
        <div class="top-section tw-flex tw-items-center">
          <p>Paddlers</p>
          <v-btn class="add-unit-btn ml-3" @click="routeToAdd()">
            <icons name="add-unit" class="mr-2" /> Add Paddler
          </v-btn>
        </div>

        <div class="tw-flex card-sculptors">
          <div class="total-unit">
            <div class="tw-flex tw-items-center">
              <icons name="paddlers" />
              <span class="darker-text ml-1">Total paddlers</span>
              <v-spacer></v-spacer>
              <span class="lighter-text">Today</span>
            </div>
            <p class="total-unit-figure mt-3">{{totalPaddlers}}</p>
            <div class="tw-flex tw-justify-end">
              <icons name="temp-graph" />
            </div>
          </div>
          <div class="categories-box">
            <div class="tw-flex tw-items-center">
              <span class="darker-text ml-1">Categories</span>
              <v-spacer></v-spacer>
              <span class="lighter-text">Today</span>
            </div>
            <div class="tw-flex tw-flex-wrap">
              <v-col cols="4" sm="6" style="padding:5px">
                <p class="total-unit-figure mt-1 small-darker-text">10</p>
                <span class="status-tags" style="color:#0F674E">Active</span>
              </v-col>
              <v-col cols="4" sm="6" style="padding:5px">
                <p class="total-unit-figure mt-1 small-darker-text">10</p>
                <span class="status-tags" style="color:#937E0B">On-leave</span>
              </v-col>
              <v-col cols="4" sm="6" style="padding:5px">
                <p class="total-unit-figure mt-1 small-darker-text">10</p>
                <span class="status-tags" style="color:#71011C ">Inactive</span>
              </v-col>
            </div>
          </div>
        </div>
        <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
          <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
            <h6 class="header">All Paddlers</h6>
            <div class="mr-2 tw-mt-5 lg:tw-mt-0 " style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)">
              <v-text-field placeholder="Search" class="p-0" solo flat hide-details v-model="search">
                <template #prepend>
                  <v-icon :color="color" class="ml-5">
                    mdi-magnify
                  </v-icon>
                </template>

                <template #append>
                  <v-icon :color="color">
                    mdi-filter-variant
                  </v-icon>
                </template>
              </v-text-field>
            </div>
            <v-data-table :headers="headers" :mobile-breakpoint="0" :search="search" item-key="id"
              :single-select="false" height="available" :hide-default-footer="paddlers.length <= 7" :items="paddlers"
              :no-data-text="not_found" class="mt-4 mx-1 table" :loading="loading" @click:row="viewSelected">
              <template #header.sno>
                <span class="status">S/N</span>
              </template>

              <template v-slot:[`item.sno`]="{ item }">

                <span class="td-style">{{ item.sno }}</span>
              </template>
              <template #header.profile>
                <span class="status">Profile</span>
              </template>

              <template v-slot:[`item.profile`]="{ item }">
                <div class="circle">
                  <span class="td-style">{{ getFirstLetter(item.firstname) }}</span>
                </div>
              </template>
              <template #header.firstName>
                <span class="status">First Name</span>
              </template>

              <template v-slot:[`item.firstName`]="{ item }">
                <span class="td-style">{{ item.firstname }}</span>
              </template>

              <template #header.lastName>
                <span class="status">Last Name</span>
              </template>

              <template v-slot:[`item.lastName`]="{ item }">
                <span class="td-style">{{ item.lastname }}</span>
              </template>

              <template #header.phoneNo>
                <span class="status">Phone Number</span>
              </template>

              <template v-slot:[`item.phoneNo`]="{ item }">
                <span class="td-style">{{ item.phoneNumber }}</span>
              </template>
              <template #header.email>
                <span class="status">Email</span>
              </template>

              <template v-slot:[`item.email`]="{ item }">
                <span class="td-style" style="text-transform: lowercase">{{item.email  }}</span>
              </template>

              <template #header.dept>
                <span class="status">Department</span>
              </template>

              <template v-slot:[`item.dept`]="{ item }">
                <span class="td-style" v-if="item.departmentAssigned">{{item.departmentAssigned.name }}</span>
              </template>

              <template #header.role>
                <span class="status">Role</span>
              </template>

              <template v-slot:[`item.role`]="{ item }">
                <span class="td-style">{{item.jobRole }}</span>
              </template>

              <template #header.actions>
                <span class="status">Actions</span>
              </template>

              <template v-slot:[`item.actions`]="{item}">
                <v-menu bottom offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <td class="d-flex justify-end">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon :color="color">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <v-list>
                    <v-list-item class="" v-if="!item.kudaAccountCreated" @click="createAccount(item)">
                      <v-list-item-title style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                        <icons name="view-paddler" class="mr-2" />
                        Create Bank Account
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="" @click="viewSelected(item)">
                      <v-list-item-title style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                        <icons name="view-paddler" class="mr-2" />
                        View paddler
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="" @click="routeToEdit(item)">
                      <v-list-item-title style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                        <icons name="update-paddler" class="mr-2" />
                        Update paddler
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="" @click="transferPaddler(item)">
                      <v-list-item-title style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                        <icons name="update-paddler" class="mr-2" />
                        Transfer paddler
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
    <transfer-paddler :toggle-modal="showModal" @close-modal="closeModal" />
  </div>
</template>

<script>
  import Icons from '../../components/reuseables/Icons.vue'
  import {
    createKudaAccount,
    getAllPaddlers
  } from '@/services/api/APIService';
  import Loader from "@/components/reuseables/Loader.vue";
  import TransferPaddler from "@/views/paddler/TransferPaddler.vue";
  // import BaseButton from "@/components/reuseables/BaseButton.vue";
  export default {
    name: "AllPaddlers",

    components: {
      TransferPaddler,
      Loader,
      Icons,
    },
    data() {
      return {
        color: "#004AAD",
        search: "",
        paddlers: [],
        not_found: "Units will show here",
        headers: [{
            text: "",
            value: "sno"
          },
          {
            text: "",
            value: "profile"
          }, {
            text: "",
            value: "firstName"
          },
          {
            text: "",
            value: "lastName"
          }, {
            text: "",
            value: "phoneNo"
          },
          {
            text: "",
            value: "email"
          },
          {
            text: "",
            value: "dept"
          },
          {
            text: "",
            value: "role"
          },
          {
            text: "",
            value: "actions",
            align: "end"
          },
        ],
        loading: false,
        totalPaddlers: 0,
        showModal: false,
        modalLoading: false,
        modalTitle: "",
        modalDesc: " ",
        transportCompanyId: null,
        selectedPost: {}

      }
    },

    methods: {
      getFirstLetter(firstname) {
        return firstname.charAt(0)
      },
      handleModal(item) {
        if (item) {
          this.selectedPost = item
        }
        this.showModal = !this.showModal
      },
      viewSelected(paddler) {
        this.$store.dispatch("paddler/setSelectedPaddler", paddler)
        this.$router.push({
          name: "ViewPaddler"
        })
      },
      routeToAdd() {
        let paddlerData = {}
        paddlerData.addressOfEmployee = {}
        paddlerData.accountDetail = {}
        paddlerData.departmentAssigned = {}
        this.$store.dispatch("paddler/setUpdateMode", false);
        this.$store.dispatch("paddler/setPaddlerData", paddlerData)
        sessionStorage.removeItem("currentPaddlerData")
        this.$router.push({
          name: "AddPaddler"
        })
      },
      async getAllPaddlers() {
        this.loading = true
        let data = {}
        data.page = 0
        data.pageSize = 100
        await getAllPaddlers(data).then(res => {
          this.paddlers = res.data
          this.paddlers.forEach((paddler, index) => (paddler.sno = index + 1));
          this.loading = false
        }).catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          })
          this.loading = false
        })
      },
      routeToEdit(item) {
        this.$store.dispatch("paddler/setUpdateMode", true);
        this.$store.dispatch("paddler/setPaddlerData", item)
        this.$router.push({
          name: 'EditPaddler',
          params: {
            staffId: item.staffId
          }
        })
      },

      transferPaddler(paddler) {
        this.$store.dispatch('paddler/setPaddlerData', paddler)
        this.showModal = true
      },
      closeModal() {
        this.showModal = false;
        this.getAllPaddlers()
      },
      createAccount(item) {
        let data = {}
        data.id = item.id
        createKudaAccount(data).then(() => {
          this.getAllPaddlers()
        })
      }
    },
    async created() {
      // window.location.reload()
      await this.getAllPaddlers()
      this.totalPaddlers = this.paddlers.length;
    },

  }
</script>

<style lang="scss" scoped>
  .sculptor {
    width: 100%;
  }

  .top-section p {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  .add-unit-btn {
    display: flex;
    color: #004AAD;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    letter-spacing: 0px;
    padding: 12px;
    border-radius: 10px;
  }

  .add-unit-btn:hover {
    background-color: #c1bfbf4c;
  }

  .total-unit {
    width: 330px;
    height: 150px;
    border-radius: 10px;
    padding: 22px;
    margin-top: 20px;
    background: #FEFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);
  }

  .categories-box {
    width: 200px;
    height: fit-content;
    padding: 10px 15px;
    flex-shrink: 0;
    margin-top: 20px;
    border-radius: 10px;
    background: #FEFFFF;
    margin-left: 20px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

    @media (max-width: 650px) {
      margin-left: 0;
    }
  }

  .categories-box,
  .total-unit {
    @media (max-width: 650px) {
      width: 100%;
    }
  }

  .darker-text,
  .lighter-text {
    color: #263238;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .lighter-text {
    color: #838383;
  }

  .total-unit-figure {
    color: #4F4F4F;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .small-darker-text {
    font-size: 16px;
  }

  .status-tags {
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .company-dashboard {
    display: flex;
    background: #FFFFFF;
    width: 100%;
    min-height: 70vh;
    border-radius: 10px;
    flex-direction: column;
  }

  .card-sculptors {
    @media (max-width: 650px) {
      flex-direction: column;
    }
  }

  .header {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #004AAD;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #7CB0F6;
    /* Set your desired background color here */
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: white;

  }

  .table {
    width: 100%;
  }

  .v-data-table__wrapper>table {
    margin-top: 55px !important;
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th:first-child,
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
    padding-left: 16px;
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
  .status {
    color: #939393;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
  .td-style {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
    text-transform: capitalize;
  }

  .company-logo {
    height: 100px;
    width: 100px;
    border-radius: 10px;
    object-fit: contain;
  }

  .no-image {
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    text-align: center;
  }

  ::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
    background: transparent !important;
    width: auto;
    height: 2px;
  }

  ::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c4c4c4 !important;
    max-height: 1px !important;
    border-radius: 10px;
  }
</style>